import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';

import { useCurrentProjectKey } from 'context/current-project';
import { createQueryKeys } from 'utils/query';

import { apiClient } from './client-sportsbook-api';

const queryKeys = createQueryKeys('bonus-banners');

export function useImages({ config = {} } = {}) {
  const projectKey = useCurrentProjectKey();

  return useQuery(
    queryKeys.list({ projectKey }),
    () => apiClient.getImages({ projectKey }),

    {
      ...config,
      enabled: Boolean(projectKey) && (config.enabled ?? true),
    }
  );
}

export function useUploadImage({ params = {}, config = {} } = {}) {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();
  const abortControllerRef = useRef();

  const { onUploadProgress } = params;

  const mutation = useMutation(
    file => {
      abortControllerRef.current = new AbortController();

      return apiClient.uploadImage(
        { projectKey, file },
        { onUploadProgress, signal: abortControllerRef.current.signal }
      );
    },
    {
      ...config,
      onSuccess: response => {
        config.onSuccess?.(response);

        return queryClient.invalidateQueries(queryKeys.list({ projectKey }));
      },
    }
  );

  return { mutation, abortControllerRef };
}
