import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { useCurrentProjectKey } from 'context/current-project';
import { useTimezone } from 'hooks/use-timezone';
import { BONUS_TYPES, SORT_BY } from 'utils/constants';
import { deserializeBonus, deserializeManager, deserializePagination } from 'utils/deserialize';
import { shouldEnableCmsv2 } from 'utils/payload-cms';
import { createQueryKeys, createPaginatedDataHook } from 'utils/query';
import { serializeBonus, serializeDateFrom, serializeDateTo } from 'utils/serialize';

import { apiClient } from './client-sportsbook-api';

export const bonusQueryKeys = createQueryKeys('bonuses');
export const authorsQueryKeys = createQueryKeys('authors');

const MAP_BONUS_TYPE_TO_DEACTIVATE_ALL_KEY = {
  [BONUS_TYPES.COMBOBOOST]: 'comboboosts',
  [BONUS_TYPES.FREEBET]: 'freebets',
  [BONUS_TYPES.HUNTING]: 'huntings',
  [BONUS_TYPES.LOOTBOX]: 'lootboxes',
  [BONUS_TYPES.TOURNAMENT]: 'tournaments',
};

const MAP_BONUS_TYPE_TO_API_KEY = {
  [BONUS_TYPES.COMBOBOOST]: 'comboboosts',
  [BONUS_TYPES.FREEBET]: 'freebets',
  [BONUS_TYPES.HUNTING]: 'huntings',
  [BONUS_TYPES.LOOTBOX]: 'huntings',
  [BONUS_TYPES.TOURNAMENT]: 'huntings',
};

export const useBonuses = createPaginatedDataHook({
  useData: ({ params = {}, config = {} }) => {
    const timezone = useTimezone();
    const projectKey = useCurrentProjectKey();
    const {
      bonusType,
      page = 1,
      limit = 25,
      sortBy = [SORT_BY.NAME.ASC],
      createdBy,
      bonusStatus,
      activationStatus,
      period,
      hasBoost,
      bonusId,
      ...restParams
    } = params;
    const isCmsv2Enabled = shouldEnableCmsv2(projectKey);

    return useQuery(
      bonusQueryKeys.list({ projectKey, ...params }),
      () =>
        apiClient.axios
          .get(`/bonuses/${MAP_BONUS_TYPE_TO_API_KEY[bonusType]}`, {
            params: {
              sort_by: sortBy,
              page,
              limit,
              bonus_state: bonusStatus || undefined,
              bonus_status: activationStatus || undefined,
              bonus_period_from: serializeDateFrom(period?.[0], timezone),
              bonus_period_to: serializeDateTo(period?.[1], timezone),
              author_ids: createdBy,
              has_boost: hasBoost,
              ...(bonusId ? { bonus_id: bonusId } : {}),
              ...restParams,
            },
            headers: {
              'X-Project': projectKey,
            },
          })
          .then(response => response.data)
          .then(({ data, pagination }) => ({
            data: data.map(bonus => deserializeBonus(bonusType, bonus, isCmsv2Enabled)),
            pagination: deserializePagination(pagination),
          })),
      {
        ...config,
        enabled: Boolean(projectKey) && (config.enabled ?? true),
      }
    );
  },
});

export const getBonus = async ({ projectKey, bonusType, bonusId, isCmsv2Enabled }, config) =>
  apiClient.axios
    .get(`/bonuses/${MAP_BONUS_TYPE_TO_API_KEY[bonusType]}/${bonusId}`, {
      ...config,
      headers: { ...config?.headers, 'X-Project': projectKey },
    })
    .then(response => deserializeBonus(bonusType, response.data, isCmsv2Enabled));

export const useBonus = ({ bonusType, bonusId, config = {} }) => {
  const projectKey = useCurrentProjectKey();

  return useQuery(
    bonusQueryKeys.detail(bonusId),
    () => getBonus({ projectKey, bonusType, bonusId }),
    {
      ...config,
      enabled:
        Boolean(projectKey) && Boolean(bonusType) && Boolean(bonusId) && (config.enabled ?? true),
    }
  );
};

export function useActivateBonus(bonusType, config = {}) {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();

  return useMutation(
    bonusId =>
      apiClient.axios.patch(
        `/bonuses/${MAP_BONUS_TYPE_TO_API_KEY[bonusType]}/${bonusId}/activate`,
        null,
        {
          headers: {
            'X-Project': projectKey,
          },
        }
      ),
    {
      ...config,
      useErrorBoundary: false,
      onSuccess: newBonus => {
        config.onSuccess?.();

        queryClient.setQueryData(bonusQueryKeys.detail(newBonus.id), newBonus);

        return queryClient.invalidateQueries(bonusQueryKeys.list({ projectKey }));
      },
    }
  );
}

export function useDeactivateBonus(bonusType, config = {}) {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();

  return useMutation(
    bonusId =>
      apiClient.axios.patch(
        `/bonuses/${MAP_BONUS_TYPE_TO_API_KEY[bonusType]}/${bonusId}/deactivate`,
        null,
        {
          headers: {
            'X-Project': projectKey,
          },
        }
      ),
    {
      ...config,
      onSuccess: newBonus => {
        config.onSuccess?.();

        queryClient.setQueryData(bonusQueryKeys.detail(newBonus.id), newBonus);

        return queryClient.invalidateQueries(bonusQueryKeys.list({ projectKey }));
      },
    }
  );
}

export function useAddBonus(bonusType, config = {}) {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();

  return useMutation(
    data =>
      apiClient.axios.post(
        `/bonuses/${MAP_BONUS_TYPE_TO_API_KEY[bonusType]}`,
        serializeBonus(bonusType, data),
        {
          headers: {
            'X-Project': projectKey,
          },
        }
      ),
    {
      ...config,
      onSuccess: () => {
        config.onSuccess?.();

        return queryClient.invalidateQueries(bonusQueryKeys.lists());
      },
    }
  );
}

export function useEditBonus({ bonusId, bonusType, config = {} }) {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();

  return useMutation(
    data =>
      apiClient.axios.put(
        `/bonuses/${MAP_BONUS_TYPE_TO_API_KEY[bonusType]}/${bonusId}`,
        serializeBonus(bonusType, data),
        {
          headers: {
            'X-Project': projectKey,
          },
        }
      ),
    {
      ...config,
      onSuccess: () => {
        config.onSuccess?.();

        return queryClient.invalidateQueries(bonusQueryKeys.lists({ projectKey }));
      },
    }
  );
}

export function useDeactivateAllBonusesByType({ bonusType, config = {} }) {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();

  return useMutation(
    () =>
      apiClient.axios.patch(
        `/bonuses/${MAP_BONUS_TYPE_TO_DEACTIVATE_ALL_KEY[bonusType]}/deactivate`,
        null,
        {
          headers: {
            'X-Project': projectKey,
          },
        }
      ),
    {
      ...config,
      onSuccess: () => {
        config.onSuccess?.();

        return queryClient.invalidateQueries(bonusQueryKeys.list({ projectKey }));
      },
    }
  );
}

export function useAuthors({ config = {} } = {}) {
  const projectKey = useCurrentProjectKey();

  return useQuery(
    authorsQueryKeys.list({ projectKey }),
    () =>
      apiClient.axios
        .get(`/bonuses/authors`, {
          headers: {
            'X-Project': projectKey,
          },
        })
        .then(response => response.data)
        .then(({ data }) => data.map(author => deserializeManager(author))),
    {
      initialData: [],
      ...config,
      enabled: Boolean(projectKey) && (config.enabled ?? true),
    }
  );
}
